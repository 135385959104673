/* root variables from figma theme, organized by section in the Figma file */
$font-families-source-sans-pro: Source Sans Pro, Arial, sans-serif;
$font-families-open-sans: Open Sans;

/* phia/color */
$color-base-100: #b1e3ff;
$color-base-200: #2aaeff;
$color-base-300: #017acc;
$color-base-400: #004d80;
$color-neutral-0: #ffffff;
$color-neutral-50: #f1f1f1;
$color-neutral-100: #e1e1e1;
$color-neutral-200: #bcbcbc;
$color-neutral-300: #7c7c7c;
$color-neutral-400: #4a4a4a;
$color-neutral-500: #2a2a2a;
$color-gradient-300: linear-gradient(92deg, #017accdb 38.02%, #017acc00 100%);
$color-content-activity-400: #8821bb;
$color-content-teacher-400: #006271;
$color-content-coral-red: #d5401b;
$color-content-activity-purple-200: #d47dff;
$color-content-teacher-100: #e0fbff;
$color-content-activity-100: #f6e4ff;
$color-info-success: #189e4e;
$color-info-warning: #e47600;
$color-info-destructive: #df313c;
$color-wireframe-100: #c4cdff;
$color-wireframe-400: #4555b0;
$bg-gradient: linear-gradient(
                90.7deg,
                #ffffff 46.73%,
                rgba(255, 255, 255, 0.4) 106.42%
); // this is not a variable in Figma
$purple-100: #edc9ff;
$purple-200: #d47dff;
$purple-300: #8821bb;
$purple-400: #460069;
$blue-100: #b1e3ff;
$blue-200: #2aaeff;
$blue-300: #017acc;
$blue-400: #004d80;
$teal-100: #c2f7ff;
$teal-200: #52baca;
$teal-300: #006271;
$teal-400: #00424c;
$green-100: #b1ffd0;
$green-200: #40dd7f;
$green-300: #189e4e;
$green-400: #0f6531;
$yellow-100: #ffe7ab;
$yellow-200: #ffd159;
$yellow-300: #f8be29;
$yellow-400: #b48e2d;
$orange-100: #ffce9a;
$orange-200: #ff9c32;
$orange-300: #e47600;
$orange-400: #a75600;
$coral-100: #ffbaa9;
$coral-200: #fd514e;
$coral-300: #d5401b;
$coral-400: #821c03;
$red-100: #ffaaaf;
$red-200: #ff555f;
$red-300: #df313c;
$red-400: #a6272f;
$black: #2a2a2a;
$grey-400: #4a4a4a;
$grey-300: #7c7c7c;
$grey-200: #bcbcbc;
$grey-100: #e1e1e1;
$grey-50: #f1f1f1;
$white: #ffffff;
$teal-50: #e0fbff;
$blue-50: #e0f4ff;
$orange-50: #ffecd7;
$red-50: #ffdddf;
$green-50: #e0ffec;

/* phia/typography */
/* TODO: convert to non-unit ratios */
$line-heights-0: 40px;
$line-heights-1: 36px;
$line-heights-2: 32px;
$line-heights-3: 24px;
$line-heights-4: 52px;
$line-heights-5: 64px;
$line-heights-6: 72px;
$line-heights-7: 50px;
$line-heights-8: 46px;
$line-heights-9: 34px;
$line-heights-10: 20px;
$line-heights-11: 16px;
$font-weights-source-sans-pro-0: Bold;
$font-weights-source-sans-pro-1: Regular;
$font-weights-open-sans-2: Bold;
$font-weights-open-sans-3: Semibold;
$font-size-0: 12px;
$font-size-1: 14px;
$font-size-2: 16px;
$font-size-3: 20px;
$font-size-4: 24px;
$font-size-5: 28px;
$font-size-6: 32px;
$font-size-7: 40px;
$font-size-8: 48px;
$font-size-9: 56px;
$font-size-10: 64px;
$letter-spacing-0: -0.3px;
$paragraph-spacing-0: 0;
$text-case-none: none;
$text-decoration-none: none;
$paragraph-indent-0: 0;
//styleName: phia/type/heading/mega;
@mixin mega {
  & {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.3px;
  }
}
//styleName: phia/type/heading/jumbo;
@mixin jumbo {
  & {
    font-size: 48px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -0.3px;
  }
}
//styleName: phia/type/heading/1;
@mixin h1($margin: true) {
  & {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
/* phia/type/heading/2 */
@mixin h2($margin: true) {
  & {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
/* phia/type/heading/3 */
@mixin h3($margin: true) {
  & {
    //font-family: $font-families-source-sans-pro;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
/* phia/type/heading/4 */
@mixin h4($margin: true) {
  & {
    //font-family: $font-families-source-sans-pro;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
/* phia/type/heading/5 */
@mixin h5($margin: true) {
  & {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
@mixin body-large($margin: true) {
  & {
    //styleName: phia/type/body/large;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
@mixin body-medium($margin: true) {
  & {
    //styleName: phia/type/body/medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
/* phia/type/body/small*/
@mixin body-small($margin: true) {
  & {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
/* phia/type/body/tag */
@mixin body-tag($margin: true) {
  & {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    letter-spacing: -0.3px;
  }

  @if $margin == true {
    & {
      margin: $space-2x 0 $space-4x;
    }
  }
}
/* phia/grid */

/* phia/space */
$space-0: 0px;
$space-half: 2px;
$space-1x: 4px;
$space-2x: 8px;
$space-3x: 12px;
$space-4x: 16px;
$space-5x: 20px;
$space-6x: 24px;
$space-7x: 28px;
$space-8x: 32px;
$space-9x: 36px;
$space-10x: 40px;
$space-11x: 44px;
$space-12x: 48px;
$space-13x: 52px;
$space-14x: 56px;
$space-15x: 60px;

/* phia/radius */
$radius-0: 0px;
$radius-half: 2px;
$radius-1x: 4px;
$radius-2x: 8px;
$radius-3x: 12px;
$radius-4x: 16px;
$radius-5x: 20px;
$radius-10x: 40px;

/* phia/shadows */
$shadow-base-3: 0px 8px 16px 0px rgba(1, 122, 204, 0.25);
$shadow-neutral-0: 0px 2px 4px rgba(74, 74, 74, 0.25);
$shadow-neutral-2: 0px 4px 8px rgba(74, 74, 74, 0.25);
$shadow-neutral-3: 0px 8px 16px rgba(74, 74, 74, 0.25);

@mixin visuallyHidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;

  &.focusable:active, &.focusable:focus {
    position: static;
		width: auto;
		height: auto;
		margin: inherit;
		overflow: visible;
		clip: auto;
  }
}

@mixin scrollbar() {
  & {
    scrollbar-width: thin; // firefox-only: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
  }

  &::-webkit-scrollbar {
    width: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: $color-neutral-100;
    border-radius: $radius-half;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: $color-base-200;
    border-radius: $radius-half;
    &:hover {
      background: $color-base-300;
    }
  }
}

@mixin elHelpers {
  @for $i from 0 through 15 {
    &.m-#{$i} {
      margin: #{$i * 4}px;
    }
    &.mx-#{$i} {
      margin-right: #{$i * 4}px;
      margin-left: #{$i * 4}px;
    }
    &.my-#{$i} {
      margin-top: #{$i * 4}px;
      margin-bottom: #{$i * 4}px;
    }
    &.mt-#{$i} {
      margin-top: #{$i * 4}px;
    }
    &.mr-#{$i} {
      margin-right: #{$i * 4}px;
    }
    &.mb-#{$i} {
      margin-bottom: #{$i * 4}px;
    }
    &.ml-#{$i} {
      margin-left: #{$i * 4}px;
    }
    &.p-#{$i} {
      padding: #{$i * 4}px;
    }
    &.px-#{$i} {
      padding-right: #{$i * 4}px;
      padding-left: #{$i * 4}px;
    }
    &.py-#{$i} {
      padding-top: #{$i * 4}px;
      padding-bottom: #{$i * 4}px;
    }
    &.pt-#{$i} {
      padding-top: #{$i * 4}px;
    }
    &.pr-#{$i} {
      padding-right: #{$i * 4}px;
    }
    &.pb-#{$i} {
      padding-bottom: #{$i * 4}px;
    }
    &.pl-#{$i} {
      padding-left: #{$i * 4}px;
    }
  }
}

@mixin lineClamp($lines: 2) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

@mixin textHelpers {
  $font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;
  @each $font-weight in $font-weights {
    .font-weight-#{$font-weight} {
      font-weight: #{$font-weight};
    }
  }
  $text-aligns: 'start', 'center', 'end';
  @each $text-align in $text-aligns {
    .text-align-#{$text-align} {
      text-align: #{$text-align};
    }
  }
  $line-clamps: 1, 2, 3;
  @each $line-clamp in $line-clamps {
    .line-clamp-#{$line-clamp} {
      @include lineClamp(1);
      -webkit-line-clamp: #{$line-clamp};
    }
  }
}

@mixin svgStroke($color) {
  svg:not([no-svg-styles]) path[stroke]:not([stroke='none']),
  svg:not([no-svg-styles]) line[stroke]:not([stroke='none']),
  svg:not([no-svg-styles]) polyline[stroke]:not([stroke='none']),
  svg:not([no-svg-styles]) polygon[stroke]:not([stroke='none']),
  svg:not([no-svg-styles]) g[stroke]:not([stroke='none']) path,
  svg:not([no-svg-styles]) g[stroke]:not([stroke='none']) line,
  svg:not([no-svg-styles]) g[stroke]:not([stroke='none']) polyline,
  svg:not([no-svg-styles]) g[stroke]:not([stroke='none']) polygon,
  svg:not([no-svg-styles]) text[stroke]:not([stroke='none']),
  svg:not([no-svg-styles]) g[stroke]:not([stroke='none']) text,
  svg:not([no-svg-styles]) circle[stroke]:not([stroke='none']),
  svg:not([no-svg-styles]) g[stroke]:not([stroke='none']) circle {
    stroke: $color;
  }
}

@mixin svgFill($color) {
  svg:not([no-svg-styles]) path[fill]:not([fill='none']),
  svg:not([no-svg-styles]) line[fill]:not([fill='none']),
  svg:not([no-svg-styles]) polyline[fill]:not([fill='none']),
  svg:not([no-svg-styles]) polygon[fill]:not([fill='none']),
  svg:not([no-svg-styles]) g[fill]:not([fill='none']) path,
  svg:not([no-svg-styles]) g[fill]:not([fill='none']) line,
  svg:not([no-svg-styles]) g[fill]:not([fill='none']) polyline,
  svg:not([no-svg-styles]) g[fill]:not([fill='none']) polygon,
  svg:not([no-svg-styles]) text[fill]:not([fill='none']),
  svg:not([no-svg-styles]) g[fill]:not([fill='none']) text,
  svg:not([no-svg-styles]) circle[fill]:not([fill='none']),
  svg:not([no-svg-styles]) g[fill]:not([fill='none']) circle {
    fill: $color;
  }
}

@mixin linkStyles($color, $hoverColor, $svgHoverColor) {
  &, &:link, &:visited, &:active {
    color: $color;
  }

  @media (hover: hover) {
    &:hover, &:link:hover, &:visited:hover, &:active {
      transition: 150ms ease-out;
      transition-property: color, background, border, opacity, transform;
      color: $hoverColor;
    }
  }
}

@mixin svgHoverStyles($color, $hoverColor, $svgHoverColor) {
  @include svgStroke($color);
  @include svgFill($color);

  @media (hover: hover) {
    &:hover, &:link:hover, &:visited:hover, &:active {
      transition: 150ms ease-out;
      transition-property: color, background, border, opacity, transform;

      svg g,
      svg path,
      svg line,
      svg polyline,
      svg polygon,
      svg text {
        transition: fill 150ms ease-out, stroke 150ms ease-out;
      }

      @include svgStroke($svgHoverColor);
      @include svgFill($svgHoverColor);
    }
  }
}

@mixin gradientBorderStyles($radius: 4, $z: -1) {
  &::before {
    content: '';
    position: absolute;
    z-index: $z;
    inset: 0;
    padding: 1.5px;
    border-radius: $radius + px;
    background: linear-gradient(
      93.02deg,
      rgba(1, 122, 204, 0.36) 23.24%,
      rgba(255, 255, 255, 0.29) 79.51%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    pointer-events: none;
  }
}
