@use '../../vars' as *;

@layer edwin {
  .primary {
    color: $color-base-300;
  }
  .success {
    color: $color-info-success;
  }
  .warning {
    color: $color-info-warning;
  }
  .error {
    color: $color-info-destructive;
  }

  .large {
    @include body-large();
  }
  .medium {
    @include body-medium();
  }
  .small {
    @include body-small();
  }
  .tag {
    @include body-tag();
  }

  .noMargin {
    margin: 0;
  }

  @include textHelpers();
}